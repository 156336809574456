import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import { Col, Row } from 'react-bootstrap'

import useWindowSize from '@shared/hooks/windowSize'
import Stories from '@shared/components/stories'
import { allRoutes } from '@shared/constants/allRoutes'
import Slider from '@shared/components/slider'

// const Slider = dynamic(() => import('@shared-components/slider'))
const CustomLink = dynamic(() => import('@shared/components/customLink'))
const StoriesLightBox = dynamic(() => import('@shared/components/stories/stories-light-box'))

const HomePageStories = ({ stories }) => {
  const { t } = useTranslation()
  const [width] = useWindowSize()

  if (stories?.length) {
    return (
      <section className="common-section overflow-hidden bg-white">
        <StoriesLightBox data={stories} />
        <Row className="justify-content-center">
          <Col xl={5} md={7}>
            <h4 className="line-title text-uppercase text-center overflow-hidden mb-3 mb-md-4">
              <span className="rounded-pill position-relative d-inline-block">{t('common:CricStories')}</span>
            </h4>
          </Col>
        </Row>
        <Slider gap={10} nav={width > 1199} destroyBelow={2200} navClass="mx-3" id="enable-stories-player">
          {stories?.map((data, index) => (
            <div key={data?._id}>
              <Stories data={data} />
            </div>
          ))}
        </Slider>
        <Row className="justify-content-center mt-3 mt-md-4">
          <Col xl={5} sm={7}>
            <CustomLink href={allRoutes.webStories} className="theme-btn w-100">
              <> {t('common:ViewMore')} {t('common:Stories')} &gt;</>
            </CustomLink>
          </Col>
        </Row>
      </section>
    )
  } else return null
}
HomePageStories.propTypes = {
  stories: PropTypes.array
}
export default HomePageStories
